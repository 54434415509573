import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SearchPin from './SearchPin/searchPin';
import MainPage from './MainPage/mainPage';
import ListPage from './ListPage/listPage';
import ListPageSample from './ListPageSample/listPageSample';
import Footer from './Footer/footer';
import AppInstallCheck from './AppInstallCheck/appInstallCheck';

function App() {
  return (
    <div className='App'>
      <Router>
        <main className="content">
          <Routes>
            <Route path="/SearchPin" element = { <SearchPin />} />
            <Route path="/MainPage" element = { <MainPage />} />
            <Route path="/" element = { <MainPage />} />
            <Route path="/ListPage" element = { <ListPage />} />
            <Route path="/ListPageSample" element = { <ListPageSample />} />
            
            <Route path="/AppInstallCheck" element = { <AppInstallCheck /> } />
          </Routes>
        </main>
        {/* <Footer /> */}
      </Router>
    </div>

  );
}

export default App;
